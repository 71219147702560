/* Component - Blockquote */

blockquote {
	padding: 10px 20px;
	margin: 0 0 20px;
	font-size: 17.5px;
	border-left: 5px solid #e5e5e5;
}
blockquote .small, 
blockquote footer, 
blockquote small {
	display: block;
	font-size: 80%;
	line-height: 1.42857143;
	color: #707478;
}
blockquote-reverse, blockquote.pull-right {
	padding-right: 15px;
	padding-left: 0;
	text-align: right;
	border-right: 5px solid #e5e5e5;
	border-left: 0;
}