/* Widget - Registered Users List */

.registered-users-list {
	list-style-type: none;
	margin: 7.5px;
	padding: 0;
}
.registered-users-list  > li {
	width: 25%;
	font-size: 13px;
	float: left;
	padding: 7.5px;
	color: $black;
}
.registered-users-list > li > a {
	display: block;
	margin-bottom: 10px;
}
.registered-users-list > li img {
	border-radius:  3px;
	max-width: 100%;
}
.registered-users-list  > li .username {
	font-size: 13px;
	color: $black;
	margin-bottom: 0;
	font-weight: 600;
}
.registered-users-list > li .username small {
	display: block;
	font-size: 11px;
	color: #707478;
	line-height: 15px;
}