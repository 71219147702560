/* Pages - Login V3 */
.login.login-with-news-feed .news-caption,
.register.register-with-news-feed .news-caption {
	color: rgba(255, 255, 255, 0.9);
	padding: 0;
	font-size: 18px;
}
.login.login-with-news-feed .news-caption .caption-title,
.register.register-with-news-feed .news-caption .caption-title {
	color: #fff;
	font-weight: 300;
	font-size: 36px;
}

.register.register-with-news-feed .register-header {
	padding: 60px 60px 0;
	font-size: 32px;
}

.register.register-with-news-feed .register-header small {
	color: #707478;
	display: block;
	font-size: 14px;
	margin-top: 10px;
	line-height: 20px;
}

.login.login-with-news-feed .login-header .brand {
	color: $black_darker;
}

.login.login-with-news-feed .login-header .brand small {
	color: #707478;
}

.login.login-with-news-feed img.news-feed-logo,
.register.register-with-news-feed img.news-feed-logo {
	width: 400px;
	height: auto;
}


.login.login-with-news-feed .login-box-wrapper {
    max-width:1200px;
}

.login.login-with-news-feed .login-box-info {
	margin-left: 2rem;
	background: #96899b;
	padding: 20px 15px;
	font-size: 16px;

	h2 {
		color: white;
		font-size: 18px;
		font-family: Verdana;
	}

	li {
		color: white;
		font-family: Verdana;
	}

	p {
		color: white !important;
		font-family: Verdana;
	}
}

.login.login-with-news-feed .login-box-info.box-2 {
	background: rgba(255, 255, 255, 0.85);
}

.login.login-with-news-feed .login-content,
.register.register-with-news-feed .register-content {
	width: auto;
	padding: 100px 60px 30px 60px;
}

.register.register-with-news-feed .register-content {
	padding: 20px 60px 30px;
}

.news-feed {
	width: 75%;
}
.news-feed .news-caption .news-feed-logo {
	padding: 2rem 0 0 2rem;
}

@media (min-width: 768px) {
	.login.login-with-news-feed,
	.register.register-with-news-feed {
		width: 100%;
		margin: 0;
		padding: 0;
		top: 0;
	}
	.login.login-with-news-feed .news-feed,
	.register.register-with-news-feed .news-feed {
		position: fixed;
		left: 0;
		right: 500px;
		top: 0;
		bottom: 0;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		overflow: hidden;
	}
	.login.login-with-news-feed .news-image,
	.register.register-with-news-feed .news-image {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		background-color: #2d1437;
		background-image: url(/images/cover-image-farmer.png);
		background-size: 50%;
		background-repeat: no-repeat;
		background-position: right 2rem bottom 2rem;
	}
	.login.login-with-news-feed .news-image img,
	.register.register-with-news-feed .news-image img {
		position: absolute;
		right: 0;
		top: 0;
		left: 0;
		bottom: 0;
		max-height: 100%;
		min-width: 100%;
		top: -1960px;
		bottom: -1960px;
		left: -1960px;
		right: -1960px;
		margin: auto;
	}
	.login.login-with-news-feed .news-caption,
	.register.register-with-news-feed .news-caption {
		position: absolute;
		/*bottom: 0;*/
		left: 0;
		right: 0;
		z-index: 20;
		/*font-weight: 300;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$black_darker00000', endColorstr='$black_darker000',GradientType=0 );*/
	}
	.login.login-with-news-feed .right-content,
	.register.register-with-news-feed .right-content {
		float: right;
		width: 25%;
	}
	.login.login-with-news-feed img.news-feed-logo,
	.register.register-with-news-feed img.news-feed-logo {
		width: 280px;
		height: auto;
	}
	.login.login-with-news-feed .login-header,
	.register.register-with-news-feed .register-header {
		/*position: relative;
		top: 0;
		margin: 0;
		left: 0;
		right: 0;
		padding: 100px 60px 0;*/
		display:none;
	}
	.login.login-with-news-feed .login-header .icon {
		top: 98px;
		right: 10px;
	}
	.news-feed {
		width: 75%;
	}
}

@media (max-width: 1024px) {
	.login.login-with-news-feed, .register.register-with-news-feed {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		overflow: scroll;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}
	.login.login-with-news-feed .right-content, 
	.register.register-with-news-feed .right-content {
		//width: 360px;
		width: 25%;
	}
	.login.login-with-news-feed .news-feed, 
	.register.register-with-news-feed .news-feed {
		right: 360px;
	}
	.login.login-with-news-feed .login-header, 
	.register.register-with-news-feed .register-header,
	.login.login-with-news-feed .login-content, 
	.register.register-with-news-feed .register-content {
		padding-left: 45px;
		padding-right: 45px;
	}
	.login.login-with-news-feed img.news-feed-logo,
	.register.register-with-news-feed img.news-feed-logo {
		width: 240px;
		height: auto;
	}
	.news-feed {
		width: 75%;
	}
}
@media (max-width: 991.98px) {
	.btn.btn-lg {
		font-size: 12px;
		line-height: 20px;
	}
	.login.login-with-news-feed .news-caption,
	.register.register-with-news-feed .news-caption {
		/*font-size: 14px;*/
		//padding: 15px 30px;
		min-width: inherit;
	}
	.login.login-with-news-feed .news-caption .caption-title,
	.register.register-with-news-feed .news-caption .caption-title {
		font-size: 32px;
	}
	.login.login-with-news-feed img.news-feed-logo,
	.register.register-with-news-feed img.news-feed-logo {
		width: 200px;
		height: auto;
	}
	.login.login-with-news-feed .login-box-info {
		margin-left: 2rem;
		background: #96899b;
		padding: 20px 15px;
		font-size: 10px;

		h2 {
			color: white;
			font-size: 12px;
			font-family: Verdana;
		}

		h4 {
			font-size: 11px;
		}

		li {
			color: white;
			font-family: Verdana;
		}

		p {
			color: white !important;
			font-family: Verdana;
		}
	}
	.news-feed {
		width: 75%;
	}
}
@media (max-width: 767.98px)  {
	.btn.btn-lg {
		font-size: 16px;
		line-height: 24px;
	}
	.login.login-with-news-feed .news-feed,
	.register.register-with-news-feed .news-feed {
		/*display: none;*/
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		//background-image: url(/images/cover-login-new.png);
	}

	.login.login-with-news-feed {
		background-color: #2d1437;
	}

	.login.login-with-news-feed img.news-feed-logo,
	.register.register-with-news-feed img.news-feed-logo {
		display:none;
	}
	.login.login-with-news-feed .right-content,
	.register.register-with-news-feed .right-content {
		float: none;
		width: auto;
		background-color: #2d1437;
	}
	.login.login-with-news-feed .login-header .icon {
		right: 60px;
	}
	.login.login-with-news-feed .login-header,
	.register.register-with-news-feed .register-header {
		padding: 40px 40px 0;
	}
	.login.login-with-news-feed .login-box-info {
		margin: 0 8px;
		background: #96899b;
		padding: 20px 15px;
		font-size: 16px;

		h2 {
			color: white;
			font-size: 18px;
			font-family: Verdana;
		}

		h4 {
			font-size: 17px;
		}

		li {
			color: white;
			font-family: Verdana;
		}

		p {
			color: white !important;
			font-family: Verdana;
		}
	}
	.news-feed {
		width: 100%;
	}
}
@media (max-width: 480px) {
	.btn.btn-lg {
		font-size: 16px;
		line-height: 24px;
	}
	.login.login-with-news-feed .login-header .icon {
		display: none;
	}
	.login.login-with-news-feed .login-header,
	.register.register-with-news-feed .register-header {
		padding: 20px 20px 0;
	}
	.login.login-with-news-feed .login-content,
	.register.register-with-news-feed .register-content {
		padding: 20px 40px;
	}
	.login.login-with-news-feed .login-box-info {
		margin: 0 8px;
		background: #96899b;
		padding: 20px 15px;
		font-size: 16px;

		h2 {
			color: white;
			font-size: 18px;
			font-family: Verdana;
		}

		h4 {
			font-size: 17px;
		}

		li {
			color: white;
			font-family: Verdana;
		}

		p {
			color: white !important;
			font-family: Verdana;
		}
	}
	.news-feed {
		width: 100%;
	}
}
@media (max-width: 360px) {
	.btn.btn-lg {
		font-size: 16px;
		line-height: 24px;
	}
	.login.login-with-news-feed .login-header,
	.register.register-with-news-feed .register-header {
		padding: 40px 30px 0;
	}
	.login.login-with-news-feed .login-content,
	.register.register-with-news-feed .register-content {
		padding: 20px 30px;
	}
	.login.login-with-news-feed .login-box-info {
		margin: 0 8px;
		background: #96899b;
		padding: 20px 15px;
		font-size: 16px;

		h2 {
			color: white;
			font-size: 18px;
			font-family: Verdana;
		}

		h4 {
			font-size: 17px;
		}

		li {
			color: white;
			font-family: Verdana;
		}

		p {
			color: white !important;
			font-family: Verdana;
		}
	}
	.news-feed {
		width: 100%;
	}
}