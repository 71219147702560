/* Widget - Widget Map */
			
.widget-map {
	background: #fff;
}
.widget-map.widget-map-rounded {
	border-radius: 4px;
}
.inverse-mode.widget-map {
	background: $black;
}