/* Page List */

/*@import 'pages/_coming_soon';
@import 'pages/_email_compose';
@import 'pages/_email_detail';
@import 'pages/_email_inbox';
@import 'pages/_error';
@import 'pages/_gallery';
@import 'pages/_invoice';
@import 'pages/_login_v2';
@import 'pages/_login_V3';
@import 'pages/_login';
@import 'pages/_map';
@import 'pages/_profile';
@import 'pages/_search_result';
@import 'pages/_timeline';*/

@import 'pages/_login_gavilon';