/* Widget - Widget List */

.widget-list-item,
a.widget-list-item {
	display: table;
	width: 100%;
	background: #fff;
	text-decoration: none;
	color: $black_darker;
}
.widget-list-rounded .widget-list-item:first-child {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
.widget-list-rounded .widget-list-item:last-child {
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
.widget-list-item .widget-list-media {
	width: 70px;
	height: 60px;
}
.widget-list-item .widget-list-media.icon {
	width: 30px;
	height: 30px;
	padding-top: 5px;
	padding-bottom: 5px;
}
.widget-list-item .widget-list-media.icon i {
	display: block;
	width: 30px;
	height: 30px;
	border-radius: 8px;
	text-align: center;
	line-height: 30px;
	font-size: 14px;
}
.widget-list-item .widget-list-media img {
	max-width: 100%;
	max-height: 100%;
}
.widget-list-item .widget-list-media,
.widget-list-item .widget-list-content,
.widget-list-item .widget-list-action {
	padding: 10px;
	display: table-cell;
	vertical-align: middle;
}
.widget-list-item + .widget-list-item .widget-list-content,
.widget-list-item + .widget-list-item .widget-list-action {
	border-top: 1px solid #eee;
}
.widget-list-item > div:first-child {
	padding-left: 15px;
}
.widget-list-item > div:last-child {
	padding-right: 15px;
}
.widget-list-item .widget-list-media + div,
.widget-list-item .widget-list-content + div,
.widget-list-item .widget-list-action + div {
	padding-left: 0;
}
.widget-list-item .widget-list-title {
	font-size: 12px;
	line-height: 16px;
	margin: 0;
}
.widget-list-item .widget-list-desc {
	font-size: 11px;
	margin: 0;
	color: #555;
}
.widget-list-item .widget-list-action {
	width: 10%;
	padding-left: 15px;
	text-align: center;
}
.panel > .widget-list .widget-list-item {
	border-radius: 0;
}
.inverse-mode .widget-list-item, 
.inverse-mode a.widget-list-item,
.inverse-mode.widget-list-item, 
 a.inverse-mode.widget-list-item {
	background: $black;
}
.inverse-mode .widget-list-item .widget-list-title,
.inverse-mode.widget-list-item .widget-list-title {
	color: #fff;
}
.inverse-mode .widget-list-item .widget-list-desc,
.inverse-mode.widget-list-item .widget-list-desc {
	color: #aaa;
}
.inverse-mode .widget-list-item + .widget-list-item .widget-list-content, 
.inverse-mode .widget-list-item + .widget-list-item .widget-list-action,
.inverse-mode.widget-list-item + .widget-list-item .widget-list-content, 
.inverse-mode.widget-list-item + .widget-list-item .widget-list-action {
	border-color: #444;
}