/* Component - Panel */

.panel {
	margin-bottom: 20px;
	background-color: #fff;
	border: none;
	box-shadow: 0 2px 10px rgba(0,0,0,.15);
	border-radius: 12px;
}
.panel .tab-content {
	border-radius: 0 0 3px 3px;
}
.panel > .table,
.panel > .tab-content {
	margin-bottom: 0;
}
.panel > .list-group, 
.panel > .panel-collapse > .list-group {
	margin-bottom: 0;
}
.panel > .list-group .list-group-item, 
.panel > .panel-collapse > .list-group .list-group-item {
	border-width: 1px 0;
	border-radius: 0;
}
.panel > .list-group:last-child .list-group-item:last-child, 
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
	border-bottom: 0;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}
.panel-heading {
	padding: 10px 15px;
	border: none;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}
.panel-heading + .table,
.panel-heading + .slimScrollDiv {
	border-top: 1px solid #eee;
}
.panel-title {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 16px;
	color: inherit;
}
.panel-title > a, 
.panel-title > small, 
.panel-title > small > a,
.panel-title > .small, 
.panel-title > .small > a {
	color: inherit;
}
.panel-heading-btn {
	float: right;
}
.panel-heading-btn > a {
	margin-left: 3px;
}
.panel-heading .btn-group .btn {
	margin-top: -7px;
}
.panel-heading .btn-group .btn.btn-sm {
	margin-top: -5px;
}
.panel-heading .btn-group .btn.btn-xs {
	margin-top: -1px;
}
.panel-heading .label.pull-left,
.panel-heading .label.pull-right {
	line-height: 15px;
}
.panel-heading .progress.pull-left,
.panel-heading .progress.pull-right {
	width: 40%;
	min-width: 120px;
}
.panel-heading + .alert {
	margin-bottom: 0;
	border-radius: 0;
}
.panel-heading .nav-tabs {
	margin-top: -10px;
	margin-right: -15px;
}
.panel-heading .nav-tabs > li > a {
	padding: 10px 15px;
	line-height: 20px;
}
.panel-with-tabs.panel-default .panel-heading {
	background: $grey_lighter;
	color: #333;
}
.panel-title {
	line-height: 20px;
	font-size: 13px;
}
.panel-title .pull-right {
	line-height: 20px;
}
.panel-title .accordion-toggle {
	margin: -10px -15px;
	padding: 10px 15px;
}
.panel-title .accordion-toggle.accordion-toggle-styled .fa:before {
	content: '\f056';
}
.panel-title .accordion-toggle.accordion-toggle-styled.collapsed .fa:before {
	content: '\f055';
}
.panel-toolbar {
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	padding: 10px 15px;
	background: #fff;
}
.panel-toolbar + .form-control {
	margin: -1px 0 0;
	border-right: none;
	border-left: none;
}
.panel-group .panel {
	margin-bottom: 0;
	border-radius: 3px;
}
.panel-group .panel + .panel {
	margin-top: 5px;
}
.panel-body {
	padding: 15px;
	border-radius: 12px !important;
}
.panel-body:before,
.panel-body:after {
	content: '';
	display: table;
	clear: both;
}
.panel-body[class^="bg-"] {
	border-radius: 0 0 3px 3px;
}
.panel-body.no-border {
	border: none !important;
}
.panel-body.panel-form,
.panel-body.no-padding,
.panel-body.panel-table,
.panel-body.panel-full-width {
	padding: 0 !important;
}
.panel-body.with-table > .table {
	border: 0;
	margin: 0;
}
.panel-body.with-table > .table tr:last-child th,
.panel-body.with-table > .table tr:last-child td{
	border-bottom: 0;
}
.panel-footer {
	background: #fff;
	border-top: 1px solid #eee;
	padding: 10px 15px;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}
.form-control + .panel-footer {
	border-top: none;
}
.panel-default > .panel-heading {
	background: $silver_lighter;
}
.panel-default > .panel-heading + .panel-collapse .panel-body {
	border-top: 1px solid #eee;
}
.panel-info > .panel-heading,
.panel-danger > .panel-heading,
.panel-inverse > .panel-heading,
.panel-success > .panel-heading,
.panel-warning > .panel-heading,
.panel-primary > .panel-heading {
	color: #fff;
}
.panel-inverse > .panel-heading { background: $black_darker; }
.panel-success > .panel-heading { background: $teal; }
.panel-warning > .panel-heading { background: #c47d15; }
.panel-danger > .panel-heading { background: #cc4946; }
.panel-primary > .panel-heading { background: #2a72b5; }
.panel-info > .panel-heading { background: #3a92ab; }

.panel > .table-bordered > tbody > tr > td:last-child, 
.panel > .table-bordered > tbody > tr > th:last-child, 
.panel > .table-bordered > tfoot > tr > td:last-child, 
.panel > .table-bordered > tfoot > tr > th:last-child, 
.panel > .table-bordered > thead > tr > td:last-child, 
.panel > .table-bordered > thead > tr > th:last-child, 
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child, 
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child, 
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child, 
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child, 
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child, 
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child {
	border-right: 0;
}
.panel > .table-bordered > tbody > tr > td:first-child, 
.panel > .table-bordered > tbody > tr > th:first-child, 
.panel > .table-bordered > tfoot > tr > td:first-child, 
.panel > .table-bordered > tfoot > tr > th:first-child, 
.panel > .table-bordered > thead > tr > td:first-child, 
.panel > .table-bordered > thead > tr > th:first-child, 
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child, 
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child, 
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child, 
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child, 
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child {
	border-left: 0;
}
.panel > .table-bordered > tbody > tr:last-child > td, 
.panel > .table-bordered > tbody > tr:last-child > th, 
.panel > .table-bordered > tfoot > tr:last-child > td, 
.panel > .table-bordered > tfoot > tr:last-child > th, 
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td, 
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th, 
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td, 
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
	border-bottom: 0;
}
.panel > .panel-collapse > .table,
.panel > .table, .panel > .table-responsive > .table {
	margin-bottom: 0;
}
.panel > .table-bordered, 
.panel > .table-responsive > .table-bordered {
	border: 0;
}
.panel.panel-no-rounded-corner,
.panel.panel-no-rounded-corner .panel-body,
.panel.panel-no-rounded-corner .panel-footer,
.panel.panel-no-rounded-corner .panel-heading {
	border-radius: 0 !important;
}
.panel.panel-hover-icon .panel-heading .panel-heading-btn .btn i {
	visibility: hidden;
}
.panel.panel-hover-icon .panel-heading:hover .panel-heading-btn .btn i,
.panel.panel-hover-icon .panel-heading:focus .panel-heading-btn .btn i {
	visibility: visible;
}


/* Component - Panel - Panel Expand */

.panel.panel-expand {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: 0;
	overflow-x: scroll;
	z-index: 1080;
}
.panel-expand .header,
.panel-expand .sidebar,
.panel-expand .sidebar-bg {
	z-index: 0;
}
.panel.panel-expand,
.panel.panel-expand > .panel-body,
.panel.panel-expand > .panel-heading {
	border-radius: 0;
}
.panel-expand .height-xs,
.panel-expand .height-sm,
.panel-expand .height-md,
.panel-expand .height-lg,
.panel-expand .height-full {
	height: 100% !important;
}
.panel.panel-expand > .panel-heading {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1020;
}
.panel.panel-expand > .panel-heading + div {
	margin-top: 40px;
}
.panel.panel-expand > .panel-heading .fa.fa-expand:before {
	content: '\f066';
}
.panel.panel-expand > .panel-footer {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}
.panel-expand .content .row [class^="col-"] {
	position: inherit;
}
.panel-expand .content .panel.panel-expand .row > [class^="col-"] {
	position: relative;
}
.panel-expand .vertical-box .vertical-box-column {
	display: table-cell;
}
.panel-expand .page-content-full-height .content {
	-webkit-transform: none;
	transform: none;
}


/* Component - Panel - Panel loading */

.panel.panel-loading .panel-body {
	position: relative;
	z-index: 0;
}
@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}
@-webkit-keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}
.panel.panel-loading .panel-body .panel-loader {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: #fff;
	opacity: 0.9;
	-webkit-animation: fadeIn .2s;
	animation: fadeIn .2s;
	z-index: 1020;
	border-radius: 0 0 4px 4px;
}