/* Widget - Widget Icon */

.widget-icon {
	position: relative;
	overflow: hidden;
}
.widget-icon,
.widget-icon i {
	display: block;
	text-align: center;
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 28px;
}
.widget-icon.user i {
	font-size: 36px;
	line-height: 56px;
}
.widget-icon.widget-icon-xs,
.widget-icon.widget-icon-xs i {
	width: 20px;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
}
.widget-icon.widget-icon-xs.user i {
	font-size: 18px;
	line-height: 28px;
}
.widget-icon.widget-icon-sm,
.widget-icon.widget-icon-sm i {
	width: 30px;
	height: 30px;
	line-height: 30px;
	font-size: 21px;
}
.widget-icon.widget-icon-sm.user i {
	font-size: 27px;
	line-height: 42px;
}
.widget-icon.widget-icon-lg,
.widget-icon.widget-icon-lg i {
	width: 60px;
	height: 60px;
	line-height: 60px;
	font-size: 42px;
}
.widget-icon.widget-icon-lg.user i {
	font-size: 54px;
	line-height: 84px;
}
.widget-icon.widget-icon-xl,
.widget-icon.widget-icon-xl i {
	width: 80px;
	height: 80px;
	line-height: 80px;
	font-size: 56px;
}
.widget-icon.widget-icon-xl.user i {
	font-size: 72px;
	line-height: 112px;
}