/* Page Footer */

.footer {
    z-index: 1020;
    padding: 10px 0;
    margin: 0 30px 0 250px;
    border-top: 1px solid #CAD0D6;
    line-height: 20px;

    &.footer-bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
    }
}