/* Component - Popover */

.popover {
	font-family: inherit;
	padding: 0;
	border: none;
	box-shadow: 0 10px 30px 0px rgba(0,0,0,.2);
}
.popover-title {
	padding: 10px 12px;
	background: #f5f5f5;
	border: none;
	font-size: 13px;
	line-height: 16px;
}
.popover-content {
	padding: 12px;
	min-width: 240px;
}
.popover > .arrow {
	border-color: transparent !important;
}