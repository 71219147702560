/* Variable */

$bg_body: #fbfbfb !default;

$silver_lighter: #FAFAFA !default;
$silver: #EEEEEE !default;
$silver_darker: #BDBDBD !default;

$black: #1B1A1A !default;
$black_darker: #000 !default;
$black_lighter: #1B1A1A !default;

$red: #F44336 !default;
$red_darker: #D32F2F !default;
$red_lighter: #E57373 !default;

$orange: #F79E00 !default;
$orange_darker: #F57C00 !default;
$orange_lighter: #FFB74D !default;

$yellow: #FFEB3B !default;
$yellow_darker: #FBC02D !default;
$yellow_lighter: #FFF176 !default;

$green: #0aaa64 !default;
$green_darker: #1B7A47 !default;
$green_lighter: #AED581 !default;

$aqua: #00BCD4 !default;
$aqua_darker: #0097A7 !default;
$aqua_lighter: #4DD0E1 !default;

$blue: #14A4E6 !default;
$blue_darker: #1976D2 !default;
$blue_lighter: #64B5F6 !default;

$purple: #9C27B0 !default;
$purple_darker: #7B1FA2 !default;
$purple_lighter: #BA68C8 !default;

$grey: #BDBDBD !default;
$grey_darker: #616161 !default;
$grey_lighter: #E0E0E0 !default;

$yellow: #FFEB3B !default;
$yellow_darker: #FBC02D !default;
$yellow_lighter: #FFF176 !default;

$lime: #CDDC39 !default;
$lime_darker: #AFB42B !default;
$lime_lighter: #DCE775 !default;

$teal: #009688 !default;
$teal_darker: #00796B !default;
$teal_lighter: #4DB6AC !default;

$indigo: #3F51B5 !default;
$indigo_darker: #303F9F !default;
$indigo_lighter: #7986CB !default;

$pink: #E91E63 !default;
$pink_darker: #C2185B !default;
$pink_lighter: #F06292 !default;

$white: #ffffff !default;

$viterra_green: #0aaa64;
$viterra_green_80: #53c493;
$viterra_green_50: #85d4b1;
$viterra_green_20: #ceeee0;
$viterra_green_10: #e7f7ef;

$viterra_purple: #2d1437;
$viterra_purple_80: #57435f;
$viterra_purple_50: #96899b;
$viterra_purple_20: #d5d0d7;
$viterra_purple_10: #eae8eb;

// Text Color
$body_text_color: #1c1c1c !default;
$heading_text_color: #212121 !default;
$heading_small_text_color: #777 !default;
$form_label_color: #212121 !default;
$link_color: #0aaa64;
$link_active_color: #0aaa64;

// Font Setting
$body_font_size: 14px !default;
$body_font_family: Verdana !default;
$ie8_body_font_family: Verdana !default;