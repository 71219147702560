$colors: #7abb00 #50642B #90ed7d #f7a35c #8085e9 #f15c80 #e4d354 #2b908f #f45b5b #91e8e1 !default;
$font-family: proxima-nova,"Helvetica Neue",Helvetica,Arial,sans-serif !default;
@import "../node_modules/highcharts/css/highcharts.css";


.highcharts-plot-background {
  fill: url('https://mygavilon.gate39site.com/images/chart_logo.png')
}

.highcharts-button.highcharts-button-disabled{
  opacity:0.2;
}

.highcharts-color-0 {
  fill: #0aaa64;
  stroke: #0aaa64;
}

.highcharts-color-1 {
  fill: #2d1437;
  stroke: #2d1437;
}
