/* Component - Nav Tabs */

.nav-tabs {
	background: $white;
	border-radius: 0;
}
.nav-tabs-inverse > .nav-tabs,
.nav-tabs.nav-tabs-inverse {
	background: $black_darker;
}
.nav-tabs .nav-link {
	padding: 10px 15px;
}
.nav-tabs > li > a {
	margin-right: 5px;
	line-height: 20px;
}
.nav-tabs.nav-justified > li > a {
	border-radius: 0;
}
.nav-tabs {
	border-width: 0 0 2px 0;
}
.nav-tabs > li > a,
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus,
.nav-tabs.nav-justified > li > a,
.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
	/*border: none !important;*/
	border-width: 0 0 4px 0;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link.active,
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
	color: $black_darker;
	border-color: white white #0aaa64 white;
}
.nav-tabs.nav-tabs-inverse > li.active > a,
.nav-tabs.nav-tabs-inverse > li.active > a:hover,
.nav-tabs.nav-tabs-inverse > li.active > a:focus,
.nav-tabs.nav-tabs-inverse .nav-link.active,
.nav-tabs.nav-tabs-inverse .nav-link.active:hover,
.nav-tabs.nav-tabs-inverse .nav-link.active:focus,
.nav-tabs.nav-tabs-inverse .nav-item.show .nav-link {
	color: $black_darker;
	background: #e7f7ef;
}
.nav-tabs.nav-tabs-inverse > li > a:hover,
.nav-tabs.nav-tabs-inverse > li > a:focus,
.nav-tabs.nav-tabs-inverse .nav-link:hover,
.nav-tabs.nav-tabs-inverse .nav-link:focus {
	color: #fff;
	background: none;
}
.nav-tabs .nav-item,
.nav-tabs > li,
.nav-tabs.nav-justified > li {
	margin-bottom: 0;
}