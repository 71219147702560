/* Theme - Gavilon */

.navbar-logo,
.coming-soon .brand .logo,
.login .login-header .brand .logo {
	border-color: #81C784 #7DBE4C #388E3C;
}

.top-menu .nav > li.active > a,
.top-menu .nav > li.active > a:focus,
.top-menu .nav > li.active > a:hover {
	background: #7DBE4C;
}

.header.navbar-inverse .navbar-nav > li > a:hover,
.header.navbar-inverse .navbar-nav > li > a:focus {
	color: #fff;
	background: #666;
}
.sidebar .nav > li.active > a,
.sidebar .nav > li.active > a:focus,
.sidebar .nav > li.active > a:hover,
.page-sidebar-minified .sidebar .nav > li.has-sub.active:focus > a,
.page-sidebar-minified .sidebar .nav > li.has-sub.active:hover > a,
.page-sidebar-minified .sidebar .nav > li.has-sub.active > a,
.label.label-theme,
.btn.btn-scroll-to-top {
	background: #0aaa64;
}
.navbar.navbar-inverse .navbar-brand {
	color: #fff !important;
}
.text-theme,
.sidebar .sub-menu > li.active > a:before {
	color: #0aaa64;
}

.page-with-light-sidebar .sidebar .nav > li > a:hover {
	opacity:0.6;
}
.gradient-enabled .navbar.navbar-inverse .navbar-brand,
.gradient-enabled .sidebar .nav > li.active > a,
.gradient-enabled .sidebar .nav > li.active > a:focus,
.gradient-enabled .sidebar .nav > li.active > a:hover,
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active:focus > a,
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active:hover > a,
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active > a,
.gradient-enabled .label.label-theme,
.gradient-enabled .btn.btn-scroll-to-top,
.gradient-enabled .top-menu .nav > li.active > a,
.gradient-enabled .top-menu .nav > li.active > a:focus,
.gradient-enabled .top-menu .nav > li.active > a:hover {
	opacity: 1.0;
	background: rgb(125,190,76);
	background: #0aaa64; 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7DBE4C', endColorstr='#388e3c',GradientType=0 );
}
.pace-progress {
	background: #0aaa64;
}
.pace .pace-activity {
	border-top-color: #0aaa64;
	border-left-color: #0aaa64;
}
.page-with-light-sidebar .sidebar .nav > li.active > a > i {
	color: #fff;
}
.page-with-light-sidebar .sidebar .nav > li > a .badge,
.page-with-light-sidebar .sidebar .nav > li > a .label.label-theme,
.page-with-light-sidebar .sidebar .nav > li.active > a .badge,
.page-with-light-sidebar .sidebar .nav > li.active > a .label.label-theme {
	background: $pink;
}
.page-with-light-sidebar .sidebar .text-theme {
	color: $pink;
}
.page-with-light-sidebar .sidebar.sidebar-grid .nav > li > a {
	border-bottom: none;
	border-top: 1px solid $silver;
}
