/*
::  1.0 Page Structure
-------------------------------------------
	1.1  Page Header
		 1.1.1 Page Header - Mega Menu (Added in V1.7)
	1.2  Page Sidebar
		 1.2.1 Page Sidebar - Floating Sub Menu
		 1.2.2 Page Sidebar - Wide Sidebar (Added in V1.7)
	1.3  Page Top Menu (Added in V1.9)
	1.4  Page Content
	1.5  Page Footer

::  2.0 Page Options
-------------------------------------------
	2.1  Page Option - Two Sidebar
	2.1  Page Option - Two Sidebar
	2.2  Page Options - Boxed Layout (Added in V1.9)
	2.3  Page Option - Fixed Header
	2.4  Page Option - Right Sidebar
	2.5  Page Option - Transparent Sidebar
	2.6  Page Option - Boxed Layout
	
::  3.0 Components
-------------------------------------------
	3.1  Component - Theme Panel
	3.2  Component - Breadcrumb
	3.3  Component - Form Horizontal
	3.4  Component - Table
	3.5  Component - Nav Tabs
	3.6  Component - Modal
	3.7  Component - Lead
	3.8  Component - Note
	3.9  Component - Dropdown Menu
	3.10 Component - Media
	3.11 Component - Card (Added in V4.0)

::  4.0 Widgets
-------------------------------------------
	4.1  Widget - Widget Chart (Added in V1.3)
	4.2  Widget - Superbox Setting (Added in V1.3)
	
::  5.0 Plugins
-------------------------------------------
	5.1  Plugin - jQuery Wizard
	5.2  Plugin - FullCalendar
	5.3  Plugin - Bootstrap Wizard
	5.4  Plugin - Datepicker
	5.5  Plugin - Gritter Notification
	5.6  Plugin - Bootstrap Datetimepicker
	5.7  Plugin - Pace Progress Loader (Added in V1.5)
	5.8  Plugin - DataTables 2.0 (Added in V1.9)

::  6.0 Pages
-------------------------------------------
	6.1  Page - Search Result
	6.2  Page - Gallery V1
	6.3  Page - Email
	6.4  Page - Profile
	6.5  Page - 404 Error
	6.6  Page - Map
	6.7  Page - Login V1
	6.8  Page - Invoice
	6.9  Page - Timeline (Added in V1.1)
	6.11 Page - Login V2 (Added in V1.2)
	6.12 Page - Inbox V2 (Added in V1.4)
	6.13 Page - Login / Register V3 (Added in V1.7)
	6.14 Page - Profile (Added in V1.7)
	
::  7.0 Predefined CSS
-------------------------------
	7.1 CSS - Vertical Box
	7.2 CSS - Width
	7.3 CSS - Padding / Margin / Display (Added in V4.0)
	
::  8.0 Content Right To Left (Added in V4.1)
-------------------------------
	8.1 RTL Settings
*/


/* -------------------------------
   1.0 Page Structure
------------------------------- */

/* 1.1 Page Header */

@media (min-width: 768px) {
	.header .navbar-toggle {
		display: none;
	}
	.header.navbar > .container .navbar-brand, 
	.header.navbar > .container-fluid .navbar-brand {
		margin-left: -15px;
	}
	.header .navbar-nav {
		float: left;
		margin: 0;
	}
	.header .navbar-header,
	.header .navbar-nav > li {
		float: left;
	}
	.header .navbar-right {
		float: right!important;
	}
	.header .container-fluid .navbar-right,
	.header .container .navbar-right {
		margin-right: -15px;
	}
	.header .navbar-toggle {
		display: none;
	}
	.header .navbar-collapse.collapse {
		display: block!important;
		height: auto!important;
		padding-bottom: 0;
		overflow: visible!important;
	}
}
@media (max-width: 767px) {
	.header .navbar-user .name {
		display: none;
	}
	.header .navbar-brand {
		width: auto;
	}
	.navbar-header {
		border-bottom: 1px solid #eee;
	}
	.navbar-inverse .navbar-header {
		border-bottom: 1px solid #2d353c;
	}
	.header .navbar-nav {
		margin-right: 0;
		padding: 0 15px;
	}
	.header .container-fluid .navbar-nav,
	.header .container .navbar-nav {
		margin: 0 -10px;
	}
	.navbar-right {
		float: right;
	}
	.navbar-left {
		float: left;
	}
	.navbar-nav > li {
		float: left;
	}
	.header .navbar-form {
		margin: 0 !important;
		padding: 9px 10px;
		border: none;	
		box-shadow: none;
	}
	.header .navbar.navbar-inverse .navbar-form {
		margin: 0;
		padding: 10px;
	}
	.header .navbar-form .btn-search {
		right: 10px;
	}
	.header .navbar-form .form-group {
		margin: 0;
	}
	.header .navbar-form .form-control,
	.header .navbar-form .form-control:focus {
		margin: 0;
		width: 200px !important;
		-webkit-animation: none !important;
		animation: none !important;
	}
	.header .navbar-nav .open .dropdown-menu {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 1000;
		float: left;
		min-width: 160px;
		border-top: 1px solid #eee;
		list-style: none;
		background: #fff;
		box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.2);
	}
	.navbar-nav.navbar-right .open .dropdown-menu {
		right: 0;
		left: auto;
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu .divider {
		background-color: #eee;
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
		color: #262626;
	}
	.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
	.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
		background: #edf0f5;
		color: #262626;
	}
	.navbar-nav .open .dropdown-menu > li > a {
		padding: 5px 15px;
	}
	.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, 
	.navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
		background: #edf0f5;
	}
	.navbar-inverse .navbar-header.navbar-header-without-bg {
	  background: none;
	  border-bottom: 1px solid #666;
	  border-bottom: 1px solid rgba(255,255,255, 0.1);
	}
	.header .navbar-collapse .navbar-nav {
		margin: 0;
		padding: 0;
	}
	.header .navbar-collapse .navbar-nav > li > a,
	.header .navbar-collapse .navbar-nav > li .dropdown-toggle {
		padding: 15px 20px;
	}
	.header .navbar-collapse .dropdown-menu > li > a {
		padding: 5px 20px;
	}
	.header .navbar-collapse {
		max-height: 340px;
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
  }
  .header .navbar-collapse .dropdown-menu.dropdown-menu-lg {
	flex: none;
  }
  .header .navbar-collapse .dropdown-menu,
  .header .navbar-collapse .navbar-nav > li + li {
	border-top: 1px solid #eee;
  }
  .header .navbar-collapse .dropdown.show {
	display: block;
  }
	.header .navbar-collapse .show .dropdown-menu {
		position: relative !important;
		box-shadow: none !important;
		width: auto !important;
		top: 0 !important;
		transform: none !important;
	}
	.header .navbar-collapse.in,
	.header .navbar-collapse.show {
		overflow-y: auto !important;
	}
	.header .navbar-toggle.navbar-toggle-left {
		display: none;
	}
	.header .navbar-toggle,
	.header .navbar-toggle.pull-left {
		float: left;
		margin-left: 0;
		height: 50px;
	}
	.header .navbar-brand {
		float: none;
		height: 50px;
		width: auto;
		/*text-align: center;*/
		display: block;
		margin: 0;
	}
	.header .navbar-nav > li > a,
	.header .navbar-nav > li .dropdown-toggle {
		padding: 10px 15px;
	}
	.header .navbar-nav > li > a .label,
	.header .navbar-nav > li .dropdown-toggle .label {
		top: 4px;
	}
	.header .search-form {
		transition: none;
		height: 50px;
	}
	.header .search-form .close i, .header .search-form .search-btn i {
		line-height: 50px;
	}
}
@media (max-width: 380px) {
	.header .navbar-form .form-control,
	.header .navbar-form .form-control:focus {
		width: 160px !important;
	}
}
@media (max-width: 340px) {
	.header .navbar-nav > li > a,
	.header .navbar-nav > li .dropdown-toggle {
		padding: 10px 12px;
	}
	.header .navbar-user img,
	.header .navbar-user .image,
	.header .navbar-user .image.image-icon {
		margin-right: 3px;
	}
	.header .navbar-user > a .caret,
	.header .navbar-user .dropdown-toggle .caret {
		margin-right: -5px;
	}
	.header .navbar-nav > li > a .label,
	.header .navbar-nav > li .dropdown-toggle .label {
		right: 2px;
	}
	.navbar-form .form-control,
	.navbar-form .form-control:focus {
		width: 140px !important;
	}
}


/* 1.1.1 Page Header - Mega Menu (Added in V1.7) */

@media (max-width: 767px) {
	.navbar-collapse {
		float: none !important;
		border-top: none;
		border-bottom: 1px solid #eee;
	}
	.navbar-collapse .nav > li {
		float: none;
	}
	.navbar-collapse .open:before,
	.navbar-collapse .open:after {
		content: '';
		display: table;
		clear: both;
	}
	.navbar-collapse .open .dropdown-menu {
		position: relative;
		box-shadow: none;
		padding-top: 0;
		border-bottom: 1px solid #eee;
		border-radius: 0;
		background: #f4f6f7;
		width: 100%;
	}
	.navbar-collapse .open .dropdown-menu .dropdown-header {
		padding-top: 20px;
		padding-left: 0;
	}
}


/* 1.2 Page Sidebar */

@media (max-width: 767px) {
	
	.sidebar {
		position: absolute;
		left: -220px;
		padding-top: 0;
		z-index: 1030;
	}
	.sidebar-bg {
		left: -220px;
		z-index: 1020;
		box-shadow: none;
	}
	@keyframes sidebarSlideInLeft {
		from { left: -220px; }
		to { left: 0; }
	}
	@-webkit-keyframes sidebarSlideInLeft {
		from { left: -220px; }
		to { left: 0; }
	}
	@keyframes sidebarSlideInRight {
		from { right: -220px; }
		to { right: 0; }
	}
	@-webkit-keyframes sidebarSlideInRight {
		from { right: -220px; }
		to { right: 0; }
	}
	.page-sidebar-toggled .sidebar {
		left: 0;
		animation: sidebarSlideInLeft .2s;
		-webkit-animation: sidebarSlideInLeft .2s;
	}
	.page-sidebar-toggled .sidebar-bg {
		position: fixed;
		background: #2d353c;
		left: 0;
		animation: sidebarSlideInLeft .2s;
		-webkit-animation: sidebarSlideInLeft .2s;
	}
	.page-sidebar-toggled.page-with-right-sidebar .sidebar,
	.page-sidebar-toggled.page-with-right-sidebar .sidebar-bg {
		left: auto;
		right: 0;
		animation: sidebarSlideInRight .2s;
		-webkit-animation: sidebarSlideInRight .2s;
	}
	.page-sidebar-minified.page-sidebar-minified .sidebar {
		position: fixed !important;
	}
	.page-sidebar-toggled.page-sidebar-minified .sidebar-bg {
		position: fixed;
		background: #2d353c;
		z-index: 1010;
		left: 0;
		animation: sidebarSlideInLeft .2s;
		-webkit-animation: sidebarSlideInLeft .2s;
			box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
	}
	.page-with-right-sidebar.page-sidebar-toggled.page-sidebar-minified .sidebar-bg {
		left: auto;
		right: 0;
		animation: sidebarSlideInRight .2s;
		-webkit-animation: sidebarSlideInRight .2s;
	}
	.page-sidebar-minified.page-with-right-sidebar .content {
		margin-right: 0;
	}
	.page-sidebar-minified.page-with-right-sidebar .footer {
		margin-right: 20px;
	}
}


/* 1.2.1 Page Sidebar - Floating Sub Menu */

@media (max-width: 767px) {
	.float-sub-menu-container {
		z-index: 1060;
	}
}


/* 1.2.2 Page Sidebar - Wide Sidebar (Added in V1.7) */

@media (max-width: 767px) {
	@keyframes wideSidebarSlideInLeft {
		from { left: -250px; }
		to { left: 0; }
	}
	@-webkit-keyframes wideSidebarSlideInLeft {
		from { left: -250px; }
		to { left: 0; }
	}
	@keyframes wideSidebarSlideInRight {
		from { right: -250px; }
		to { right: 0; }
	}
	@-webkit-keyframes wideSidebarSlideInRight {
		from { right: -250px; }
		to { right: 0; }
	}
	.page-sidebar-toggled.page-with-wide-sidebar .sidebar,
	.boxed-layout .page-sidebar-toggled.page-with-wide-sidebar.page-container #sidebar {
		left: 0;
		animation: wideSidebarSlideInLeft .2s;
		-webkit-animation: wideSidebarSlideInLeft .2s;
	}
	.page-sidebar-toggled.page-with-wide-sidebar .sidebar-bg,
	.boxed-layout .page-sidebar-toggled.page-with-wide-sidebar.page-container .sidebar-bg {
		left: 0;
		animation: wideSidebarSlideInLeft .2s;
		-webkit-animation: wideSidebarSlideInLeft .2s;
	}
	.page-sidebar-toggled.page-with-right-sidebar.page-with-wide-sidebar .sidebar,
	.page-sidebar-toggled.page-with-right-sidebar.page-with-wide-sidebar .sidebar-bg,
	.page-right-sidebar-toggled.page-with-wide-sidebar.page-with-two-sidebar .sidebar.sidebar-right,
	.page-right-sidebar-toggled.page-with-wide-sidebar.page-with-two-sidebar .sidebar-bg.sidebar-right {
		left: auto;
		right: 0;
		animation: wideSidebarSlideInRight .2s;
		-webkit-animation: wideSidebarSlideInRight .2s;
	}
	.page-with-wide-sidebar .sidebar,
	.page-with-wide-sidebar .sidebar-bg,
	.boxed-layout .page-with-wide-sidebar:not(.page-sidebar-minified) #sidebar,
	.boxed-layout .page-with-wide-sidebar:not(.page-sidebar-minified) .sidebar-bg {
		left: -250px;
		width: 250px;
	}
	.page-with-wide-sidebar.page-with-right-sidebar .sidebar,
	.page-with-wide-sidebar.page-with-right-sidebar .sidebar-bg,
	.page-with-wide-sidebar.page-with-two-sidebar .sidebar.sidebar-right, 
	.page-with-wide-sidebar.page-with-two-sidebar .sidebar-bg.sidebar-right {
		left: auto;
		right: -250px;
	}
	.page-with-wide-sidebar.page-with-right-sidebar .content {
		margin-right: 0;
	}
}


/* 1.3 Page Top Menu (Added in V1.9) */

@media (max-width: 767px) {
	.page-with-top-menu .sidebar {
		padding-top: 0;
	}
	.top-menu {
		position: relative;
		top: 0;
		height: auto;
		display: none;
	}
	.top-menu .container,
	.top-menu .container-fluid {
		padding: 0;
	}
	.top-menu .nav > li {
		display: block;
	}
	.top-menu .nav > li + li {
		margin-left: 0;
	}
	.top-menu .nav {
		margin: 0 !important;
	}
	.top-menu .nav > li > a .caret {
		float: right;
		margin-top: 0px;
	}
	.top-menu .nav .sub-menu {
		position: relative;
		top: 0;
		width: 100%;
	}
	.top-menu .sub-menu > li > a {
		padding-left: 44px;
	}
	.top-menu .nav li.has-sub.active > .sub-menu,
	.top-menu .nav li.has-sub.active:hover > .sub-menu,
	.top-menu .nav li.has-sub.active:active > .sub-menu {
		display: block;
	}
	.top-menu .nav > li.has-sub:hover > .sub-menu,
	.top-menu .nav > li.has-sub:focus > .sub-menu {
		display: none;
	}
	.top-menu .nav > li.menu-control {
		display: none !important;
	}
	.page-sidebar-toggled.page-sidebar-minified .sidebar.sidebar-transparent + .sidebar-bg {
		z-index: 1020;
	}
}


/* 1.4 Page Content */

@media (max-width: 767px) {
	.content {
		padding: 20px 8px;
	}
	.content,
	.page-sidebar-minifed .content {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.content.content-full-width .breadcrumb {
		margin: 20px;
	}
	.content.content-full-width .page-header,
	.content.content-full-width .breadcrumb {
		margin: 20px;
	}
	.content.content-full-width .page-header + .breadcrumb,
	.content.content-full-width .breadcrumb + .page-header {
		margin-top: -15px;
	}
}


/* 1.5 Page Footer */

@media (max-width: 767px) {
	.footer {
		margin: 0 20px !important;
	}
}



/* -------------------------------
   2.0 Page Options
------------------------------- */

/* 2.1 Page Option - Two Sidebar */

@media(max-width: 979px) {
	@keyframes sidebarSlideInRight {
		from { right: -220px; }
		to { right: 0; }
	}
	@-webkit-keyframes sidebarSlideInRight {
		from { right: -220px; }
		to { right: 0; }
	}
	.page-with-two-sidebar .content {
		margin-right: 0 !important;
	}
	.page-with-two-sidebar .sidebar.sidebar-right,
	.page-with-two-sidebar .sidebar-bg.sidebar-right { 
		right: -220px;
		left: auto !important;
		position: fixed;
	}
	.page-with-two-sidebar.page-sidebar-toggled .sidebar.sidebar-right,
	.page-with-two-sidebar.page-sidebar-toggled .sidebar-bg.sidebar-right {
		animation: none;
		-webkit-animation: none;
	}
	.page-with-two-sidebar.page-right-sidebar-toggled .sidebar.sidebar-right,
	.page-with-two-sidebar.page-right-sidebar-toggled .sidebar-bg.sidebar-right {
		animation: sidebarSlideInRight .2s;
		-webkit-animation: sidebarSlideInRight .2s;
		right: 0;
		left: auto;
	}
}
@media (max-width: 767px) {
	.page-with-two-sidebar .header .navbar-brand {
		float: none;
		display: block;
		margin: 0 70px;
		text-align: center;
		width: auto;
	}
	.page-with-two-sidebar .header .navbar-brand .navbar-logo {
		position: relative;
		top: 3px;
		display: inline-block;
		float: none;
		margin-top: 0;
		margin-right: 5px;
	}
}


/* 2.2 Page Options - Boxed Layout (Added in V1.9) */

@media (max-width: 1080px) {
	.boxed-layout .page-container,
	.boxed-layout .page-container #header,
	.boxed-layout .page-container #top-menu,
	.boxed-layout .pace {
		width: 100%;
	}
	.boxed-layout .top-menu,
	.boxed-layout .pace {
		margin-left: 0;
		left: 0;
		right: 0;
	}
}
@media (max-width: 767px) {
	.boxed-layout .page-container #sidebar,
	.boxed-layout .page-container .sidebar-bg {
		left: -220px;
	}
	.boxed-layout .page-sidebar-toggled #sidebar,
	.boxed-layout .page-sidebar-toggled .sidebar-bg {
		left: 0;
	}
}


/* 2.3 Page Option - Fixed Header */

@media (max-width: 767px) {
	.page-header-fixed {
		padding-top: 101px;
	}
}


/* 2.4 Page Option - Right Sidebar */

@media (max-width: 767px) {
	.page-with-right-sidebar .sidebar,
	.page-with-right-sidebar .sidebar-bg {
		left: auto;
		right: -220px;
	}
	.page-with-right-sidebar .content {
		margin-right: 0;
	}
	.page-with-right-sidebar .footer {
		margin-right: 20px;
	}
}


/* 2.5 Page Option - Transparent Sidebar */

@media (max-width: 767px) {
	.page-sidebar-toggled.page-sidebar-minified .sidebar.sidebar-transparent + .sidebar-bg, 
	.sidebar.sidebar-transparent + .sidebar-bg {
		background-size: 100% 100%;
	}
}


/* 2.6 Page Option - Boxed Layout */

@media (max-width: 767px) {
	 .boxed-layout .top-menu {
		top: 109px;
	}
}



/* -------------------------------
   3.0 Components
------------------------------- */

/* 3.1 Component - Theme Panel */

@media (max-width: 767px) {
	.theme-panel .control-label.double-line {
		line-height: 30px;
	}
	.theme-panel {
		top: 0;
		bottom: 0;
		z-index: 1080;
	}
	.theme-panel .theme-collapse-btn {
		top: 150px;
	}
	.theme-panel .theme-panel-content {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0;
		overflow: scroll;
		border-radius: 0;
	}
}


/* 3.2 Component - Breadcrumb */

@media (max-width: 767px) {
	.breadcrumb {
		float: none !important;
	}
	.breadcrumb > li {
		line-height: 20px;
	}
}


/* 3.3 Component - Form Horizontal */

@media(max-width: 979px) {
	.form-horizontal .control-label[class*="col-md-"]:not([class*="col-sm-"]):not([class*="col-xs-"]),
	.form-horizontal .control-label[class*="col-lg-"]:not([class*="col-sm-"]):not([class*="col-xs-"]) {
		padding-bottom: 5px;
	}
	.form-horizontal.form-bordered .form-group {
		border-bottom: 1px solid #eee;
	}
	.form-horizontal.form-bordered .form-group > .control-label {
		padding: 15px;
		line-height: 34px;
	}
	.form-horizontal.form-bordered .form-group > div {
		padding: 15px;
	}
	.form-horizontal.form-bordered .form-group > .control-label[class*="col-md-"]:not([class*="col-sm-"]):not([class*="col-xs-"]),
	.form-horizontal.form-bordered .form-group > .control-label[class*="col-lg-"]:not([class*="col-sm-"]):not([class*="col-xs-"]) {
		padding: 15px 15px 0;
		line-height: 14px;
	}
	.form-horizontal.form-bordered .form-group > div[class*="col-md-"]:not([class*="col-sm-"]):not([class*="col-xs-"]),
	.form-horizontal.form-bordered .form-group > div[class*="col-lg-"]:not([class*="col-sm-"]):not([class*="col-xs-"]) {
		padding: 15px 15px 15px;
	}
	.form-horizontal.form-bordered .form-group > div,
	.form-horizontal.form-bordered .form-group > .control-label {
		border: none;
	}
}
@media (max-width: 767px) {
	.form-horizontal .control-label,
	.form-horizontal .col-form-label {
		padding-top: 0;
		margin-bottom: 0;
		text-align: left;
	}
	.form-horizontal.form-bordered .form-group > .control-label {
		padding: 15px 15px 0;
		line-height: inherit;
	}
	.form-horizontal.form-bordered .form-group > div {
		padding: 0px 15px 15px !important;
	}
	.form-bordered .form-group > .col-form-label, 
	.form-horizontal.form-bordered .form-group > .control-label {
		padding-top: 15px;
		padding-bottom: 5px;
	}
}


/* 3.4 Component - Table */

@media (max-width: 767px) {
	.table-responsive .table > thead > tr > th, 
	.table-responsive .table > tbody > tr > th, 
	.table-responsive .table > tfoot > tr > th, 
	.table-responsive .table > thead > tr > td, 
	.table-responsive .table > tbody > tr > td, 
	.table-responsive .table > tfoot > tr > td {
		white-space: nowrap;
	}
}


/* 3.5 Component - Nav Tabs */

@media (max-width: 767px) {
	.nav-tabs.nav-justified.nav-justified-mobile > li {
		display: table-cell;
		width: 1%;
	}
	.nav-tabs.nav-justified.nav-justified-mobile > li > a {
		margin-bottom: 0;
	}
}


/* 3.6 Component - Modal */

@media (max-width: 767px) {
	.modal-message .modal-header, .modal-message .modal-body, .modal-message .modal-footer {
		width: 80%;
	}
}


/* 3.7 Component - Lead */

@media (max-width: 767px) {
	.lead {
		font-size: 16px;
	}
}


/* 3.8 Component - Note */

@media(max-width: 480px) {
	.note .note-icon,
	.note .note-icon i {
		position: relative;
		display: block;
		left: 0;
		top: 0;
		margin: 0;
	}
	.note .note-icon {
		padding: 15px;
		margin: -15px -15px 15px;
		border-radius: 4px 4px 0 0;
		width: auto;
	}
	.note .note-icon + .note-content {
		margin: 0;
	}
}


/* 3.9 Component - Dropdown Menu */

@media(max-width: 480px) {
	.dropdown-menu.media-list {
		max-width: 280px;
	}
	.dropdown-menu.media-list p {
		max-width: 190px;
	}
}
@media (max-width: 380px) {
	.dropdown-menu.media-list {
		margin-right: -50px;
	}
}


/* 3.10 Component - Media */

@media(max-width: 480px) {
	.media {
		display: block;
	}
	.media.media-lg .pull-left {
		float: none !important;
		margin-right: 0;
	}
	.media.media-lg .media-object {
		width: 100%;
		margin-bottom: 10px;
	}
}


/* 3.11 Component - Card (Added in V4.0) */

@media (min-width: 576px) {
	.card-deck {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-flow: row wrap;
			-ms-flex-flow: row wrap;
				flex-flow: row wrap;
	}
	.card-deck .card {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 1;
		-webkit-flex: 1 0 0%;
			-ms-flex: 1 0 0%;
				flex: 1 0 0%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
			-ms-flex-direction: column;
				flex-direction: column;
	}
	.card-deck .card:not(:first-child) {
		margin-left: 15px;
	}
	.card-deck .card:not(:last-child) {
		margin-right: 15px;
	}
}
@media (min-width: 576px) {
	.card-group {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-flow: row wrap;
			-ms-flex-flow: row wrap;
				flex-flow: row wrap;
	}
	.card-group .card {
		-webkit-box-flex: 1;
		-webkit-flex: 1 0 0%;
			-ms-flex: 1 0 0%;
				flex: 1 0 0%;
	}
	.card-group .card + .card {
		margin-left: 0;
		border-left: 0;
		border-left: 1px solid #e2e7eb;
	}
	.card-group .card:first-child {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
	}
	.card-group .card:first-child .card-img-top {
		border-top-right-radius: 0;
	}
	.card-group .card:first-child .card-img-bottom {
		border-bottom-right-radius: 0;
	}
	.card-group .card:last-child {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
	}
	.card-group .card:last-child .card-img-top {
		border-top-left-radius: 0;
	}
	.card-group .card:last-child .card-img-bottom {
		border-bottom-left-radius: 0;
	}
	.card-group .card:not(:first-child):not(:last-child) {
		border-radius: 0;
	}
	.card-group .card:not(:first-child):not(:last-child) .card-img-top,
	.card-group .card:not(:first-child):not(:last-child) .card-img-bottom {
		border-radius: 0;
	}
}
@media (min-width: 576px) {
	.card-columns {
		-webkit-column-count: 3;
		   -moz-column-count: 3;
				column-count: 3;
		-webkit-column-gap: 1.25rem;
		   -moz-column-gap: 1.25rem;
				column-gap: 1.25rem;
	}
	.card-columns .card {
		display: inline-block;
		width: 100%;
		margin-bottom: 15px;
	}
}



/* -------------------------------
   4.0 Widgets
------------------------------- */

/* 4.1 Widget - Widget Chart (Added in V1.3) */

@media (max-width: 540px) {
	.widget-chart .widget-chart-content {
		margin-right: 0;
	}
	.widget-chart .widget-chart-sidebar,
	.widget-chart .widget-chart-sidebar .chart-legend {
		position: relative;
		width: auto;
	}
	.widget-chart .widget-chart-sidebar .chart-legend {
		margin-top: 20px;
	}
}


/* 4.2 Widget - Superbox Setting (Added in V1.3) */

@media (max-width: 979px) {
	.superbox-list {
		width: 20%;
	}
}
@media (max-width: 767px) {
	.superbox-list {
		width: 25%;
	}
}
@media (max-width: 480px) {
	.superbox-list {
		width: 50%;
	}
}



/* -------------------------------
   5.0 Widgets
------------------------------- */

/* 5.1 Plugin - jQuery Wizard */

/*@media (max-width: 1024px) {
	.sw-main .nav-tabs {
		white-space: nowrap;
		display: block !important;
		overflow-y: scroll;
	}
}*/


/* 5.2 Plugin - FullCalendar */

/*@media (max-width: 767px) {
	.fc-event .fc-event-title small {
		display: none !important;
	}
	.calendar {
		display: block;
		width: 100% !important;
	}
	.calendar {
		margin-left: 0 !important;
	}
	.fc-button-today {
		float: left !important;
	}
	.fc-header-left,
	.fc-header-center,
	.fc-header-right {
		text-align: center !important;
		width: 100% !important;
		display: table !important;
	}
	.calendar-event .external-event {
		width: 100% !important;
	}
	.fc-toolbar {
		margin-bottom: 0px !important;
	}
	.fc .fc-toolbar > * > * {
		margin-bottom: 10px !important;
	}
	.fc-toolbar .fc-center {
		margin-top: 0px !important;
	}
	.fc .fc-toolbar h2 {
		font-size: 18px !important;
		line-height: 28px !important;
	}
	.calendar-event .external-event h5 {
		margin: 0 !important;
	}
}*/


/* 5.3 Plugin - Bootstrap Wizard */

/*@media (max-width: 767px) {
	.bwizard-steps li {
		float: left !important;
		width: 100% !important;
	}
}*/


/* 5.4 Plugin - Datepicker */

/*@media(max-width: 480px) {
	.datepicker table tr td, .datepicker table tr th {
		padding: 7px !important;
	}
}*/


/* 5.5 Plugin - Gritter Notification */

/*@media (max-width: 420px) {
	#gritter-notice-wrapper {
		width: 320px !important;	
	}
	.gritter-close, .gritter-light .gritter-close {
		width: 60px !important;
	}
	.gritter-with-image, .gritter-without-image {
		padding-right: 60px !important;
	}
	.gritter-image {
		width: 40px !important;
		height: 40px !important;
		margin-top: 0 !important;
	}
	.gritter-item {
		padding: 0 10px 5px !important;
	}
	.gritter-with-image {
		width: 250px !important;
	}
}
@media (max-width: 360px) {
	#gritter-notice-wrapper {
		width: 280px !important;	
	}
	.gritter-with-image {
		width: 210px !important;
	}
}*/


/* 5.6 Plugin - Bootstrap Datetimepicker */

/*@media (max-width: 360px) {
	body .bootstrap-datetimepicker-widget.dropdown-menu,
	.datepicker.datepicker-dropdown {
		width: 260px !important;
	}
}*/


/* 5.7 Plugin - Pace Progress Loader (Added in V1.5) */

@media (max-width: 767px) {
	.pace-progress {
		top: 101px;
	}
	.pace .pace-activity {
		top: 112px
	}
}


/* 5.8 Plugin - DataTables 2.0 (Added in V1.9) */

/*@media (max-width: 767px) {
	.dt-buttons.btn-group {
		float: none;
		text-align: center;
		display: block;
		margin: 0 0px 5px;
	}
	.dt-buttons.btn-group > .btn {
		float: none;
		margin-bottom: 5px;
		border-radius: 4px !important;
	}
	.dt-buttons.btn-group > .btn + .btn {
		border-left: none;
		margin-left: 5px;
	}
	.dataTables_length,
	div.dataTables_info {
		float: none;
		margin-right: 0;
	}
	.panel > .table-responsive > .dataTables_wrapper div.dataTables_length label,
	.panel > .dataTables_wrapper div.dataTables_length label {
		margin: 20px 0 0;
	}
	div.dataTables_wrapper div.dataTables_info {
		margin: 0;
		padding-top: 0 !important;
	}
	div.dataTables_wrapper div.dataTables_paginate {
		margin: 10px 20px 0 !important;
		text-align: center;
	}
	div.dataTables_wrapper div.dataTables_paginate .pagination {
		display: block;
		white-space: initial !important;
	}
	div.dataTables_wrapper div.dataTables_paginate .pagination > li > a {
		float: none;
		margin-bottom: 5px;
		display: inline-block;
		border-radius: 4px;
	}
	div.dataTables_wrapper div.dataTables_paginate .pagination > li + li a {
		margin-left: 5px;
	}
}*/



/* -------------------------------
   6.0 Pages
------------------------------- */

/* 6.1 Page - Search Result */

/*@media (max-width: 1024px) {
	.result-list .result-image {
		display: block;
		height: 200px;
		width: auto;
	}
}
@media(max-width: 979px) {
	.result-list .result-price {
		width: 200px;
	}
}
@media (max-width: 767px) {
	.result-list .result-price {
		width: 100px;
		font-size: 20px;
	}
	.result-list > li > div {
		padding: 15px;
	}
}
@media(max-width: 480px) {
	.result-list .result-image {
		width: 100%;
		float: none;
	}
	.result-list .result-image a {
		position: relative;
	}
	.result-list > li > div {
		display: block;
		width: 100%;
	}
	.result-list > li > .result-price {
		width: 100%;
		padding: 15px;
	}
	.result-list .btn-row {
		text-align: center;
	}
	.result-list .result-price .btn {
		margin-top: 15px;
	}
}*/


/* 6.2 Page - Gallery V1 */

/*@media(max-width: 979px) {
	.gallery .image {
		width: 33%;
	}
}
@media (max-width: 767px) {
	.gallery .image {
		width: 50%;
	}
}
@media(max-width: 480px) {
	.gallery .image {
		width: 100%;
	}
}*/


/* 6.3 Page - Email */

/*@media(max-width: 480px) {
	.email-content {
		margin: 0 -20px;
		padding: 0;
		overflow: scroll;
	}
}*/


/* 6.4 Page - Profile */

/*@media (max-width: 767px) {
	.profile-content,
	.profile-header .profile-header-content {
		padding: 20px;
	}
	.profile-header-img {
		width: 66px;
		height: 66px;
		margin-top: 10px;
	}
	.profile-header .profile-header-tab {
		margin: 0;
		padding: 0;
		white-space: nowrap;
		overflow-y: scroll;
		display: block;
	}
	.profile-header .profile-header-tab > li {
		float: none;
	}
	.profile-header-img + .profile-header-info {
		margin-left: 80px;
	}
}*/


/* 6.5 Page - 404 Error */

@media (max-width: 767px) {
	.error-code {
		bottom: 50%;
	}
	.error-content {
		top: 50%;
	}
}
@media(max-width: 480px) {
	.error-code {
		font-size: 128px;
		line-height: 128px;
	}
}


/* 6.6 Page - Map */

@media (max-width: 767px) {
	.map {
		top: 101px !important;
		left: 0 !important;
		right: 0 !important;
	}
	.map .jvectormap-zoomin {
		top: 120px;
	}
	.map .jvectormap-zoomout {
		top: 150px;
	}
}


/* 6.7 Page - Login V1 */

/*@media (max-width: 767px) {
	.login .login-header,
	.login-content {
		width: 100% !important;
	}
	.login {
		margin: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.login .login-header {
		left: 0;
		margin: 0;
		position: relative;
		top: 0;
		padding-top: 60px;
	}
	.login .login-header .icon {
		display: none;
	}
	.login .login-header .brand {
		text-align: center;
		color: #fff;
	}
	.login .login-header .brand small {
		opacity: 0.5;
		color: #fff;
	}
	.login .login-header .brand .logo {
		border-width: 25px;
		display: block;
		margin: 0 auto;
	}
}*/


/* 6.8 Page - Invoice */

/*@media (max-width: 767px) {
	.invoice-company .pull-right {
		float: none !important;
		display: block;
	}
	.invoice .table-responsive {
		margin-bottom: 20px;
	}
	.invoice-from, .invoice-to, .invoice-date {
		display: block !important;
		width: 100% !important;
		text-align: left !important;
		padding: 0 !important;
	}
	.invoice-to, .invoice-date {
		margin-top: 20px !important;
	}
	.invoice-price .invoice-price-left,
	.invoice-price .invoice-price-right {
		display: block;
		width: 100% !important;
		float: left !important;
		padding: 10px !important;
	}
	.invoice-price .invoice-price-row {
		float: none !important;
		width: 100% !important;
		display: block !important;
	}
	.invoice-price .invoice-price-left .sub-price {
		padding: 0px 10px !important;
		display: block !important;
		text-align: center !important;
	}
	.invoice-price .invoice-price-right {
		text-align: center !important;
	}
	.invoice-price .invoice-price-right small {
		position: relative !important;
		display: block;
		left: 0 !important;
		top: 0 !important;
	}
}*/


/* 6.9 Page - Timeline (Added in V1.1) */

/*@media (max-width: 979px) {
	.timeline .timeline-body {
		margin-left: 25%;
		margin-right: 10%;
	}
	.timeline .timeline-time {
		width: 13%;
	}
	.timeline .timeline-icon {
		left: 13%;
		width: 12%;
	}
	.timeline:before {
		left: 19%;
	}
}
@media (max-width: 767px) {
	.timeline:before {
		left: 50%;
	}
	.timeline > li {
		padding-top: 30px;
	}
	.timeline-comment-box {
		margin-left: -20px;
		margin-right: -20px;
		padding: 15px 20px;
	}
	.timeline .timeline-body {
		margin-right: 0;
		margin-left: 0;
		margin-top: 10px;
		padding: 20px;
	}
	.timeline .timeline-footer {
		margin: 20px -20px -20px;
		padding: 20px;
	}
	.timeline .timeline-body:before {
		border-bottom-color: #fff;
		border-right-color: transparent;
		left: 50%;
		top: -20px;
		margin-left: -10px;
	}
	.timeline .timeline-time {
		right: 50%;
		left: 0;
		top: 0;
		width: auto;
		margin-right: 20px;
		padding-top: 10px;
	}
	.timeline .timeline-icon {
		left: 0;
		width: 80px;
		position: relative;
		margin: 0 auto;
		top: -10px;
	}
}*/


/* 6.10 Page - Coming Soon (Added in V1.1) */

/*@media (max-width: 979px) {
	.coming-soon .timer .is-countdown {
		width: 767px;
	}
}
@media (max-width: 767px) {
	.coming-soon .timer .is-countdown {
		width: 100%;
	}
}
@media (max-width: 480px) {
	.coming-soon .timer .countdown-amount {
		font-size: 24px;
	}
	.coming-soon .timer .countdown-period,
	.coming-soon .coming-soon-header .desc,
	.coming-soon .coming-soon-content .desc {
		font-size: 12px;
	}
	.coming-soon .coming-soon-header .desc,
	.coming-soon .coming-soon-content .desc {
		padding-right: 20px;
		padding-left: 20px;
	}
	.coming-soon .coming-soon-content .input-group {
		width: auto;
		margin-right: 20px;
		margin-left: 20px;
	}
}*/


/* 6.11 Page - Login V2 (Added in V1.2) */

/*@media (min-width: 1920px) {
	.login-cover-image img {
		width: 100%;
	}
}
@media (max-width: 767px) {
	.login.login-v2 {
		left: 0;
		top: 0;
		margin: 0;
		position: relative;
		width: 100%;
		background: none;
	}
	.login.login-v2 .login-header {
		padding-top: 60px;
	}
	.login-cover-image img {
		max-width: initial;
		max-height: 100%;
	}
	.login-bg-list {
		position: relative;
		padding: 20px;
		right: 0;
		bottom: 0;
	}
}*/


/* 6.12 Page - Inbox V2 (Added in V1.4) */

/*@media (max-width: 480px) {
	.list-email .email-time {
		float: none;
		display: block;
		margin: 0;
		background: #fff;
	}
}*/


/* 6.13 Page - Login / Register V3 (Added in V1.7) */

/*@media (max-width: 1024px) {
	.login.login-with-news-feed, .register.register-with-news-feed {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		overflow: scroll;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}
	.login.login-with-news-feed .right-content, 
	.register.register-with-news-feed .right-content {
		width: 360px;
	}
	.login.login-with-news-feed .news-feed, 
	.register.register-with-news-feed .news-feed {
		right: 360px;
	}
	.login.login-with-news-feed .login-header, 
	.register.register-with-news-feed .register-header,
	.login.login-with-news-feed .login-content, 
	.register.register-with-news-feed .register-content {
		padding-left: 45px;
		padding-right: 45px;
	}
}
@media (max-width: 992px) {
	.login.login-with-news-feed .news-caption,
	.register.register-with-news-feed .news-caption {
		font-size: 14px;
		padding: 15px 30px;
		min-width: inherit;
	}
	.login.login-with-news-feed .news-caption .caption-title,
	.register.register-with-news-feed .news-caption .caption-title {
		font-size: 32px;
	}
}
@media (max-width: 768px) {
	.login.login-with-news-feed .news-feed,
	.register.register-with-news-feed .news-feed {
		display: none;
	}
	.login.login-with-news-feed .right-content,
	.register.register-with-news-feed .right-content {
		float: none;
		width: auto;
	}
	.login.login-with-news-feed .login-header .icon {
		right: 60px;
	}
}
@media (max-width: 480px) {
	.login.login-with-news-feed .login-header .icon {
		display: none;
	}
	.login.login-with-news-feed .login-header,
	.register.register-with-news-feed .register-header {
		padding: 40px 40px 0;
	}
	.login.login-with-news-feed .login-content,
	.register.register-with-news-feed .register-content {
		padding: 20px 40px;
	}
}
@media (max-width: 360px) {
	.login.login-with-news-feed .login-header,
	.register.register-with-news-feed .register-header {
		padding: 40px 30px 0;
	}
	.login.login-with-news-feed .login-content,
	.register.register-with-news-feed .register-content {
		padding: 20px 30px;
	}
}*/


/* 6.14 Page - Profile (Added in V1.7) */

/*@media (max-width: 1024px) {
	.profile-left {
		width: 150px;
	}
	.profile-image {
		height: 130px;
	}
	.profile-right {
		margin-left: 165px;
		padding-right: 0;
	}
	.table.table-profile > tbody > tr > td.field {
		width: 100px;
	}
}
@media (max-width: 767px) {
	.profile-left {
		width: 180px;
	}
	.profile-right {
		margin-left: 195px;
		padding-right: 0;
	}
}
@media (max-width: 480px) {
	.profile-left {
		width: 100%;
		margin-bottom: 15px;
	}
	.profile-image {
		height: auto;
		text-align: center;
	}
	.profile-image img {
		margin: 0 auto;
	}
	.profile-right {
		margin: 0;
	}
	.profile-section + .profile-section {
		padding-top: 0;
	}
	.profile-section .title {
		margin-top: 20px;
	}
}*/



/* -------------------------------
   7.0 Predefined CSS
------------------------------- */

/* 7.1 CSS - Vertical Box */

@media (max-width: 767px) {
	.vertical-box,
	.vertical-box .vertical-column {
		display: block !important;
	}
	.vertical-box,
	.vertical-box-row,
	.vertical-box-column,
	.vertical-box-cell,
	.vertical-box-inner-cell,
	.page-content-full-height .content {
		display: block;
		width: auto !important;
		position: relative !important;
		overflow: initial !important;
		table-layout: initial !important;
		top: auto !important;
		height: auto !important;
	}
	.vertical-box .slimScrollBar,
	.vertical-box .slimScrollRail {
		display: none !important;
	}
	.vertical-box-widget .vertical-box-column {
		display: table-cell !important;
		height: 100% !important;
	}
	.vertical-box.vertical-box-widget {
		display: table !important;
		table-layout: fixed !important;
		border-spacing: 0 !important;
		height: 100% !important;
		width: 100% !important;
	}
}


/* 7.2 CSS - Width */

@media (max-width: 767px) {
	.width-xs,
	.width-sm,
	.width-md,
	.width-lg,
	.width-full {
		width: 100% !important;
	}
}


/* 7.3 CSS - Padding / Margin / Display (Added in V4.0) */

@media (max-width: 767px) {
	.p-l-xs-0 { padding-left: 0 !important; }
	.p-t-xs-0 { padding-top: 0 !important; }
	.p-r-xs-0 { padding-right: 0 !important; }
	.p-b-xs-0 { padding-bottom: 0 !important; }
	.p-xs-0 { padding: 0 !important; }
	
	.m-l-xs-0 { margin-left: 0 !important; }
	.m-t-xs-0 { margin-top: 0 !important; }
	.m-r-xs-0 { margin-right: 0 !important; }
	.m-xs-0 { margin: 0 !important; }
	.m-b-xs-0 { margin-bottom: 0 !important; }
	.hidden-xs {
		display: none !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.hidden-sm {
		display: none !important;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.hidden-md {
		display: none !important;
	}
}
@media (min-width: 1200px) {
	.hidden-lg {
		display: none !important;
	}
}



/* -------------------------------
   8.0 Content Right To Left (Added in V4.1)
------------------------------- */

/* 8.1 RTL Settings */

/*@media (max-width: 767px) {
	.rtl-mode .breadcrumb {
		float: none!important;
	}
	.rtl-mode .content.content-full-width .breadcrumb {
		margin: 20px;	
	}
	.rtl-mode .header .navbar-toggle.pull-left {
		margin-right: 0;
		margin-left: 15px;
	}
	.rtl-mode .header .navbar-toggle.pull-right {
		margin-left: 0;
		margin-right: 15px;
	}
	.rtl-mode .page-sidebar-toggled.page-with-right-sidebar .sidebar,
	.rtl-mode .page-with-two-sidebar.page-right-sidebar-toggled .sidebar.sidebar-right {
		z-index: 1040;
	}
	.rtl-mode .page-sidebar-toggled.page-with-right-sidebar .sidebar, 
	.rtl-mode .page-sidebar-toggled.page-with-right-sidebar .sidebar-bg {
		left: auto;
		right: 0;
		animation: sidebarSlideInRight .2s;
		-webkit-animation: sidebarSlideInRight .2s;
	}
	.rtl-mode .page-with-two-sidebar .sidebar.sidebar-right, 
	.rtl-mode .page-with-two-sidebar .sidebar-bg.sidebar-right,
	.rtl-mode .page-with-two-sidebar.page-sidebar-toggled .sidebar.sidebar-right, 
	.rtl-mode .page-with-two-sidebar.page-sidebar-toggled .sidebar-bg.sidebar-right {
		left: -220px !important;
		right: auto !important;
		-webkit-animation: none;
		animation: none;
	}
	.rtl-mode .page-with-wide-sidebar.page-with-two-sidebar .sidebar-bg.sidebar-right, 
	.rtl-mode .page-with-wide-sidebar.page-with-two-sidebar .sidebar.sidebar-right {
		left: -250px!important;
		right: auto!important;
	}
	.rtl-mode .page-with-two-sidebar.page-right-sidebar-toggled .sidebar.sidebar-right, 
	.rtl-mode .page-with-two-sidebar.page-right-sidebar-toggled .sidebar-bg.sidebar-right {
		animation: sidebarSlideInLeft .2s;
		-webkit-animation: sidebarSlideInLeft .2s;
		left: 0 !important;
		right: auto !important;
	}
	.rtl-mode .page-with-wide-sidebar .sidebar, 
	.rtl-mode .page-with-wide-sidebar .sidebar-bg, 
	.rtl-mode .boxed-layout .page-with-wide-sidebar:not(.page-sidebar-minified) #sidebar, 
	.rtl-mode .boxed-layout .page-with-wide-sidebar:not(.page-sidebar-minified) .sidebar-bg {
		right: -250px;
	}
	.rtl-mode .page-sidebar-toggled.page-with-wide-sidebar .sidebar, 
	.rtl-mode .boxed-layout .page-sidebar-toggled.page-with-wide-sidebar.page-container #sidebar {
		right: 0;
		animation: wideSidebarSlideInRight .2s;
		-webkit-animation: wideSidebarSlideInRight .2s;
	}
	.rtl-mode .top-menu .nav > li > a .caret {
		float: left;
	}
	.rtl-mode .page-sidebar-toggled .sidebar-bg {
		right: 0;
		left: auto;
		animation: sidebarSlideInRight .2s;
		-webkit-animation: sidebarSlideInRight .2s;
		z-index: 1020;
	}
	.rtl-mode .sidebar,
	.rtl-mode .sidebar-bg {
		right: -220px;
		padding-top: 0;
		z-index: 1030;
	}
	.rtl-mode .header .navbar-header {
		float: none;
	}
	.rtl-mode .header .navbar-brand {
		float: right;	
	}
	.rtl-mode .header .navbar-toggle {
		float: left;
		margin-right: 15px;
		margin-left: 0px;
	}
	.rtl-mode .page-with-two-sidebar .header .navbar-brand {
		float: none;
	}
	.rtl-mode .page-sidebar-toggled .sidebar {
		right: 0;
		animation: sidebarSlideInRight .2s;
		-webkit-animation: sidebarSlideInRight .2s;
	}
	.rtl-mode .profile-header-img + .profile-header-info {
		margin-right: 80px;
	}
	.rtl-mode .profile-header .profile-header-tab {
		padding-right: 0;
	}
	.rtl-mode .login .login-header {
		left: 0;
		margin: 0;
		right: 0;
	}
	.rtl-mode .login .login-header .brand .logo {
		margin: 0 auto;
	}
	.rtl-mode .login.login-v2 {
		left: 0;
		top: 0;
		right: 0;
		margin: 0;
		width: 100%;
	}
	.rtl-mode .login.login-with-news-feed .right-content, 
	.rtl-mode .register.register-with-news-feed .right-content {
		float: none;
		width: auto;
	}
	.rtl-mode .timeline .timeline-icon {
		left: auto;
		right: 15%;
	}
	.rtl-mode .timeline .timeline-time {
		text-align: left;
		left: auto;
		right: 0;
	}
	.rtl-mode .timeline .timeline-body {
		margin-right: 23%;
		margin-left: 0;
	}
	.rtl-mode .timeline:before {
		left: auto;
		right: 20%;
		margin-left: 0;
		margin-right: -2.5px;
	}
	.rtl-mode .timeline .timeline-body:before {
		border-left-color: #fff;
		border-right-color: transparent;
		left: auto;
		right: -20px;
	}
}*/