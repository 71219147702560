/* Plugin List */

/*@import 'plugins/_bootstrap_calendar';
@import 'plugins/_bootstrap_colorpalette';
@import 'plugins/_bootstrap_daterangepicker';
@import 'plugins/_bootstrap_datetimepicker';
@import 'plugins/_bootstrap_select';
@import 'plugins/_bootstrap_sweetalert';
@import 'plugins/_bootstrap_timepicker';
@import 'plugins/_bootstrap_wizard';
@import 'plugins/_ckeditor';
@import 'plugins/_colorpicker';
@import 'plugins/_combobox';
@import 'plugins/_datatable';
@import 'plugins/_datepicker';
@import 'plugins/_datetimepicker';
@import 'plugins/_dropzone';
@import 'plugins/_flot';
@import 'plugins/_fullcalendar';
@import 'plugins/_gritter';
@import 'plugins/_highlightjs';
@import 'plugins/_introjs';
@import 'plugins/_iris_range_slider';
@import 'plugins/_jquery_autocomplete';
@import 'plugins/_jquery_draggable';
@import 'plugins/_jquery_simple_colorpicker';
@import 'plugins/_jquery_smart_wizard';
@import 'plugins/_jstree';
@import 'plugins/_jvectormap';
@import 'plugins/_nvd3';*/
@import 'plugins/_pace_loader';
/*@import 'plugins/_parsley';
@import 'plugins/_password_indicator';
@import 'plugins/_powerange_slider';
@import 'plugins/_select2_choice';
@import 'plugins/_select2';
@import 'plugins/_slimscrollbar';
@import 'plugins/_sparkline';
@import 'plugins/_summernote';
@import 'plugins/_superbox';
@import 'plugins/_tagit';
@import 'plugins/_typeahead';
@import 'plugins/_wysihtml5';
@import 'plugins/_xeditable';*/
