/* Widget List */

@import 'widget/_general';
@import 'widget/_chart';
@import 'widget/_chat_list';
@import 'widget/_message';
@import 'widget/_stats';
@import 'widget/_theme_panel';
@import 'widget/_todolist';
@import 'widget/_unlimited_navtabs';
@import 'widget/_user_list';
@import 'widget/_widget_list';
@import 'widget/_widget_chat';
@import 'widget/_widget_input';
@import 'widget/_widget_todolist';
@import 'widget/_widget_map';
@import 'widget/_widget_icon';
@import 'widget/_widget_img';
@import 'widget/_widget_card';
@import 'widget/_widget_chart';
@import 'widget/_widget_table';