/* Widget - Media Message */

.media-messaging .media {
	padding: 15px;
}
.media-messaging .media:first-child {
	padding-top: 0;
}
.media-messaging .media:last-child {
	padding-bottom: 0;
}
.media-messaging .media p {
	margin: 0;
}
.media-messaging .media + .media {
	margin-top: 0;
	padding-top: 15px;
}