/* Component - Progress bar */

.progress {
	box-shadow: none;
	background: #e2e7eb;
}
.progress-bar {
	background-color: $blue;
	box-shadow: none;
}
.progress-xs { height: 5px; }
.progress-sm { height: 10px; }
.progress-lg { height: 30px; }
.progress-xs .progress-bar { line-height: 5px; }
.progress-sm .progress-bar { line-height: 10px; }
.progress-lg .progress-bar { line-height: 30px; }