/* Component - Nav Pills */

.nav-pills {
	margin-bottom: 10px;
}
.nav-pills > li > a.active, 
.nav-pills > li > a.active:focus, 
.nav-pills > li > a.active:hover {
	background: $black_darker;
	color: #fff;
}
.nav-pills > li > a,
.nav-pills .nav-link {
	padding: 10px 15px;
	border-radius: 3px;
}
.nav-pills .nav-link.active, 
.nav-pills .show > .nav-link,
.nav-pills > li.active > a, 
.nav-pills > li.active > a:hover, 
.nav-pills > li.active > a:focus {
	background: $black_darker;
	color: #fff;
}
.nav.nav-pills.nav-sm > li {
	margin: 0 0 3px;
}
.nav.nav-pills.nav-sm > li a {
	padding: 8px 10px;
	line-height: 1.5;
}
.nav-pills > li + li {
	margin-left: 5px;
}
.nav-stacked > li + li {
	margin-left: 0;
	margin-top: 5px;
}